import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import moment from 'moment';
import NavLink from "../components/elements/navLink"
import Thumbnail from "../components/blogThumbnail"
import styled from "styled-components"

const BlogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 1rem auto;

  & > div:last-child {
    width: auto;
  }
`

const BlogContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;

  @media screen and (min-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

type Props = {
  readMore?: boolean;
  full?: boolean
}

const BlogFeed = ({ readMore = false, full = false }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      allMediumFeed {
        edges {
          node {
            title
            link
            thumbnail
            date
          }
        }
      }
    }
  `)

  const feedArray = full ? data.allMediumFeed.edges : data.allMediumFeed.edges.slice(0, 2)

  return (
    <BlogWrapper>
      <BlogContainer>
        {feedArray.map(edge => (
          <Thumbnail
            key={edge.node.title}
            title={edge.node.title}
            image={edge.node.thumbnail}
            link={edge.node.link}
            datePublished={moment(edge.node.date).format('D. M. YYYY')}
          />
        ))}
      </BlogContainer>
      {readMore && <NavLink to="/blog/">Další články...</NavLink>}
    </BlogWrapper>
  )
}

export default BlogFeed
